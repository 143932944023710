<template>
  <section class="overlay-wizard">
    <b-row class="overlay-wizard__row" v-if="!orderId">
      <b-col
        v-for="(step, index) in steps[orderType]"
        :key="index"
        sm="6"
        md="4"
        lg="3"
        xl="2"
      >
        <component
          :is="step"
          :order-type="orderType"
          :is-active="activeStack.includes(step)"
          :disable-edits="disableEdits"
          @choosedServices="choosedServices"
          @choosedBudgetAndDuration="choosedBudgetAndDuration"
          @choosedExtras="choosedExtras"
          @projectDetailed="projectDetailed"
          @clientInfoFilled="clientInfoFilled"
        />
      </b-col>
    </b-row>
    <b-row v-else class="overlay-wizard__row">
      <transition name="fadeIn" mode="in-out">
        <b-col v-if="checkFinished" sm="6" md="4">
          <section class="contact-us__success">
            <h2 class="contact-us__success--heading">
              {{ $t("label.request_pending") }}
            </h2>
            <b-img
              :src="require('@/assets/imgs/illustrations/contactUs/sucess.svg')"
              fluid
            ></b-img>
            <p class="contact-us__success--text">
              {{ $t("label.request_success_message") }}
            </p>
          </section>
        </b-col>
        <b-col v-else sm="6" md="4">
          <attachments :orderId="orderId" @attachmented="attachmented" />
        </b-col>
      </transition>
    </b-row>
  </section>
</template>

<script>
import { StoreData } from "@/helpers/apiMethods";

export default {
  name: "OverlayWizard",

  components: {
    ProjectDetails: () => import("./components/ProjectDetails"),
    // Budget: () => import("./components/Budget"),
    // ExtraOptions: () => import("./components/ExtraOptions"),
    ChooseService: () => import("./components/ChooseService"),
    ClientInfo: () => import("./components/ClientInfo"),
    Attachments: () => import("./components/Attachments")
  },

  props: {
    orderType: {
      type: String,
      default: "service"
    },
    projectOrderId: {
      type: Number,
      required: true
    }
  },

  mounted() {
    this.InitiateActiveStack();
  },
  data() {
    return {
      steps: {
        service: [
          "ChooseService",
          // "Budget",
          // "ExtraOptions",
          "ProjectDetails",
          "ClientInfo"
        ],
        // project: ["ProjectDetails", "Budget", "ExtraOptions", "ClientInfo"]
        project: ["ProjectDetails", "ClientInfo"]
      },
      activeStack: [],
      //   orderable_ids[]
      //   extra_services[]
      order: {
        orderable_type:
          this.orderType === "service" ? "sub_service" : "project",
        orderable_ids: [],
        time_range: "",
        budget_range: "",
        order_data_name: "",
        message: "",
        examples: "",
        user_name: "",
        user_email: "",
        phone: null,
        country_code: null
      },
      //   orderableIds: [],
      extraServicesIds: [],
      disableEdits: false,
      orderId: null,
      checkFinished: false
    };
  },

  methods: {
    InitiateActiveStack() {
      if (this.orderType === "service") {
        this.activeStack.push("ChooseService");
      } else {
        this.activeStack.push("ProjectDetails");
      }
    },
    disableFinish() {
      if (this.activeStack.includes("ClientInfo")) {
        this.activeStack.pop();
      }
    },
    choosedServices(choosed) {
      // this.activeStack.push("Budget");
      this.activeStack.push("ProjectDetails");

      if (choosed.length) {
        choosed.forEach(service => {
          service.ChosenSubs.forEach(sub => {
            this.order.orderable_ids.push(sub.id);
          });
        });
      } else {
        this.order.orderable_ids = [];
        this.disableFinish();
      }
    },
    choosedBudgetAndDuration(BDObject) {
      this.activeStack.push("ExtraOptions");

      if (BDObject.done) {
        this.order.budget_range = BDObject.budget.text;
        this.order.time_range = BDObject.duration.text;
      } else {
        this.order.budget_range = "";
        this.order.time_range = "";
        this.disableFinish();
      }
    },
    choosedExtras(choosed) {
      if (this.orderType === "service") {
        this.activeStack.push("ProjectDetails");
      } else {
        this.activeStack.push("ClientInfo");
      }

      if (choosed.length) {
        choosed.forEach(extra => {
          this.extraServicesIds.push(extra.id);
        });
      } else {
        this.extraServicesIds = [];
        this.disableFinish();
      }
    },
    projectDetailed(projectInfo) {
      if (this.orderType === "service") {
        this.activeStack.push("ClientInfo");
      } else {
        this.activeStack.push("Budget");
      }

      if (projectInfo.project_name) {
        this.order.order_data_name = projectInfo.project_name;
        this.order.message = projectInfo.note;
        this.order.examples = projectInfo.example;
      } else {
        this.order.order_data_name = "";
        this.order.message = "";
        this.order.examples = "";
        this.disableFinish();
      }
    },
    clientInfoFilled(clientInfo) {
      this.order.user_name = clientInfo.name;
      this.order.user_email = clientInfo.email;
      this.order.country_code = clientInfo.country_code;
      this.order.phone = clientInfo.phone;

      // here we trigger finish requst --------
      this.handleOrder();
    },
    handleOrder() {
      // Check if Project
      if (this.projectOrderId) {
        this.order.orderable_ids = [this.projectOrderId];
      }

      // disable Edits
      this.disableEdits = true;

      // handle chosen extras
      if (this.extraServicesIds.length) {
        this.order.extra_services = [];
        this.extraServicesIds.forEach(id => {
          this.order.extra_services.push(id);
        });
      }

      //   let formData = new FormData();
      //   Object.keys(this.order).forEach(key => {
      //     if (key === "orderable_ids") {
      //       this.order[key].forEach(id => {
      //         formData.append("orderable_ids[]", id);
      //       });
      //     } else {
      //       formData.append(key, this.order[key]);
      //     }
      //   });

      // Make Order
      StoreData({
        reqName: "orders",
        data: { ...this.order }
      })
        .then(res => {
          this.disableEdits = false;
          this.orderId = res.data.order.id;
          this.analytics.fbq.event("Lead");
        })
        .catch(err => {
          this.disableEdits = false;
          console.log(err);
        });
    },
    attachmented() {
      this.checkFinished = true;
      setTimeout(() => {
        this.checkFinished = false;

        this.$router.go(-1);
        this.$store.dispatch("SetGlobalOverlay", {
          type: "",
          isOpen: false
        });
      }, 2000);
    }
  }
};
</script>
